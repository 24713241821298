<template>
  <div class="two-factor-search">
    <div v-if="!oneTypeAvailable">
      <Button
        v-if="byNumber"
        variant="secondary"
        size="lg"
        :text="$t('searchForm/searchByNumber')"
        wide
        fullHeightLanes
        @click="openSearch('byNumber')"
      ></Button>

      <div class="two-factor-search__divider">{{ $t('searchResults/or') }}</div>

      <Button
        v-if="byEmail"
        variant="secondary"
        size="lg"
        :text="$t('searchForm/searchByEmail')"
        wide
        fullHeightLanes
        @click="openSearch('byEmail')"
      ></Button>

      <div class="two-factor-search__divider">{{ $t('searchResults/or') }}</div>

      <Button
        v-if="bySelfie"
        variant="secondary"
        size="lg"
        :text="$t('searchForm/searchBySelfie')"
        icon-right="selfie"
        wide
        fullHeightLanes
        @click="openSearch('bySelfie')"
      ></Button>
    </div>

    <Button
      v-else-if="bySelfie"
      variant="primary"
      size="lg"
      :text="$t('searchForm/searchBySelfie')"
      icon-right="selfie"
      wide
      fullHeightLanes
      @click="openSearch('bySelfie')"
    ></Button>

    <TwoFactorSearchNumber
      v-if="byNumber"
      ref="byNumber"
      :isModal="!oneTypeAvailable"
      :eventId="eventId"
      :externalEventId="externalEventId"
    ></TwoFactorSearchNumber>

    <TwoFactorSearchSelfie
      v-if="bySelfie"
      ref="bySelfie"
      :eventId="eventId"
      :externalEventId="externalEventId"
    ></TwoFactorSearchSelfie>

    <TwoFactorSearchEmail
      v-if="byEmail"
      ref="byEmail"
      :isModal="!oneTypeAvailable"
      :eventId="eventId"
      :externalEventId="externalEventId"
    ></TwoFactorSearchEmail>
  </div>
</template>


<script lang="js">
import TwoFactorSearchNumber from '@/components/two-factor-search-number/two-factor-search-number.vue';
import TwoFactorSearchSelfie from '@/components/two-factor-search-selfie/two-factor-search-selfie.vue';
import TwoFactorSearchEmail from '@/components/two-factor-search-email/two-factor-search-email.vue';

export default {
  name: 'TwoFactorSearch',
  components: {
    TwoFactorSearchNumber,
    TwoFactorSearchSelfie,
    TwoFactorSearchEmail,
  },
  props: {
    eventId: String,
    externalEventId: String,
    searching: Object,
  },
  computed: {
    byNumber() {
      return this.$props.searching.byTag;
    },
    bySelfie() {
      return this.$props.searching.byFace;
    },
    byEmail() {
      return this.$props.searching.byEmail;
    },
    oneTypeAvailable() {
      return [this.byNumber, this.bySelfie, this.byEmail].filter((type) => type).length === 1;
    },
  },
  methods: {
    openSearch(type) {
      if (this.$refs[type]) {
        const item = this.$refs[type];
        item.open();
      }
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./two-factor-search";
</style>
